<template>
	<div id="dialog">
		<el-dialog :title="data.title" :visible.sync="data.show" :width="data.width" :close-on-click-modal="false"
			:close-on-press-escape="false" :before-close="cancel">
			<div class="dialog-content">
				<slot></slot>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="sure">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		props: {
			data: {
				type: Object,
				default: {
					show: true,
					title: '提示',
					width: '50%'
				}
			}
		},
		methods: {
			cancel() {
				this.data.show = false;
				this.$emit('cancel');
			},
			sure() {
				// this.data.show = false;
				this.$emit('sure');
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			}
		}
	}
</script>

<style lang="scss">
	#dialog {}
</style>
